(function() {
    'use strict';

    angular.module('aerosApp')
        .component('aflModalCarouselListInput', {
            controller: aflModalCarouselListInputController,
            templateUrl: '/static/javascript/directives/afl-modal-carousel/afl-list-input/afl-modal-carousel-list-input.html',
            bindings: {
                group: '=',
                editable: '=',
                disabled: '=',
                fieldDescription: '=',
                parentFieldDescription: '=',
                model: '=',
                fieldName: '=',
                required: '=',
                visible: '=',
                fieldChangeEventHandler: '&'
            }
        });

    function aflModalCarouselListInputController() {
        var $ctrl = this;

        $ctrl.isVisible = function isVisible() {
            return typeof $ctrl.visible !== "undefined" ?  $ctrl.visible : true;
        };

        $ctrl.getListItemByModel = function getListItemByModel() {
            return $ctrl.fieldDescription.values.find(function(item) {
                return item.value === $ctrl.model[$ctrl.fieldName];
            })
        };

        $ctrl.onChange = function onChange() {
            $ctrl.fieldChangeEventHandler({properties: {
                    fieldName: $ctrl.fieldName,
                    fieldDescription: $ctrl.fieldDescription
                }});
        };

        $ctrl.$onInit = function() {

            if($ctrl.parentFieldDescription && $ctrl.parentFieldDescription.findNearestOnParentChange
                && $ctrl.model[$ctrl.parentFieldDescription.parent] !== undefined) {
                return;
            }

            if ($ctrl.fieldDescription.required && $ctrl.model[$ctrl.fieldName] === undefined
                    && $ctrl.fieldDescription.default !== undefined) {
                $ctrl.model[$ctrl.fieldName] = $ctrl.fieldDescription.default;
            }
        };

        $ctrl.$onDestroy = function() {
            if ($ctrl.parentFieldDescription && $ctrl.parentFieldDescription.findNearestOnParentChange
                && $ctrl.model[$ctrl.parentFieldDescription.parent] !== undefined) {
                return;
            }

            delete $ctrl.model[$ctrl.fieldName];
        }
    }
})();
